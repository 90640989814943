<template>
  <div class="pt-2">
    <b-card class="factory-monitor-card">
      <div class="page-title">
        Statiscts
      </div>
      <b-card-text class="bold-gray-text">
        Here you can click the link to access MS PowerBI Reporting and statistics
      </b-card-text>

      <div class="block-wrapper">
        <div class="gray-text">
          Email: info@apolloscooters.co
        </div>
        <div class="gray-text">
          Password: Ilovescooters@1
        </div>
      </div>

      <div class="gray-text mb-1">
        <b-card-text>Email: rdp@apolloscooters.co</b-card-text>
        <b-card-text>Password: Ilovescooters@Apollo1</b-card-text>
        <b-card-text>Pin: 123456</b-card-text>
      </div>
      <a
        target="_blank"
        href="https://app.powerbi.com/groups/me/reports/2750664f-e90a-4fdf-a153-c817a458aa21/ReportSection0ab1d81941ab891a3f9c?ctid=d352523b-49ba-41ac-bcfd-64b32995a22a"
      ><b-button class="show-button">Show</b-button></a>

    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BCardText,
  },
}
</script>

<style scoped>

.factory-monitor-card .card{
  padding:20px;
}
.page-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #1d1d1d;
  border-bottom: 1px solid #F6F6F6;
  padding-bottom: 17px;
  margin-bottom: 17px;
  line-height: 21.94px;
}

.bold-gray-text {
  color: #1d1d1d;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.block-wrapper {
  border-bottom: 1px solid #F6F6F6;
  padding-bottom: 5px;
  margin-bottom: 11px;
}

.block-wrapper .gray-text {
  margin-bottom:7px;
}

.note {
  margin-bottom:3px;
  font-size: 14px;
}

.show-button {
  background: linear-gradient(46.9deg, #000000 0.23%, #757575 101.88%) !important;
  border-radius: 5px  !important;
}

.show-button:hover {
  background: linear-gradient(46.9deg, #757575 0.23%,  #000000 101.88%) !important;
  box-shadow: none !important;
}

</style>
